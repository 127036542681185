import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/admin",
      component: () => import("@/layouts/full/LayoutFull.vue"),
      beforeEnter: (to, from, next) => {
        store.dispatch("auth/checkToken");
        next();
      },
      children: [
        {
          path: "",
          name: "homeAdmin",
          redirect: "home",
          component: () => import("@/views/SecondPage.vue"),
        },
        {
          path: "home",
          name: "home",
          component: () => import("@/views/Home.vue"),
        },
        {
          path: "reports",
          name: "reports",
          component: () => import("@/views/reports.vue"),
        },
        {
          path: "products",
          name: "products",
          component: () => import("@/views/products.vue"),
        },
        {
          path: "orders",
          name: "orders",
          component: () => import("@/views/orders.vue"),
        },
        {
          path: "product/:id",
          name: "product",
          component: () => import("@/views/product.vue"),
        },
        {
          path: "order/:id",
          name: "order",
          component: () => import("@/views/order.vue"),
        },
        {
          path: "cart",
          name: "cart",
          component: () => import("@/views/cart.vue"),
        },
      ],
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
